"use client";
import { useKeycloak } from "@react-keycloak/ssr";

import { useEffect } from "react";

export default function Login() {
  const {origin} = window.location
  useEffect(() => {
    window.location.href=`${process.env.NEXT_PUBLIC_KEYCLOAK_ISSUE}${process.env.NEXT_PUBLIC_KEYCLOAK_AUTH}${origin}/callback`;
  },[]);

  return null;
}